import {Link, Head} from '@inertiajs/react';
import AppLayout from "@/Layouts/MainAppLayout.jsx";
import {Fragment} from "react";
import Box from "@mui/material/Box"; //GLOBAL CUSTOM COMPONENTS

import Newsletter from "@/components/newsletter/index.js"; //LOCAL CUSTOM COMPONENTS

import Offers from "./pages-sections/market-2/offers";
import Section1 from "./pages-sections/market-2/section-1";
import Section2 from "./pages-sections/market-2/section-2";
import Section3 from "./pages-sections/market-2/section-3";
// import Section4 from "./pages-sections/market-2/section-4";
// import Section5 from "./pages-sections/market-2/section-5";
// import Section6 from "./pages-sections/market-2/section-6";
// import Section7 from "./pages-sections/market-2/section-7";
import Shops from "./pages-sections/market-2/shops";
import Section9 from "./pages-sections/market-2/section-9";
import Section8 from "@/Pages/pages-sections/market-2/section-8/index.js";


export const brands = [{
    id: "109a66f4-b1a3-45dc-b1e9-9f6912efa928",
    slug: "samsung",
    name: "Samsung",
    image: "/assets/images/brands/samsung.png",
    type: null,
    featured: true
}, {
    id: "1f367909-644b-49f3-8e83-d84d20528e7f",
    slug: "brokeshire's",
    name: "Brokeshire’s",
    image: "/assets/images/brands/brokshire.png",
    type: null,
    featured: false
}, {
    id: "077d40ac-fc19-47ff-90b1-f0d46c7b613a",
    slug: "levis",
    name: "Levis",
    image: "/assets/images/brands/levis.png",
    type: null,
    featured: true
}, {
    id: "be171dfc-8d71-4678-90b9-87ef70be0eff",
    slug: "raymond",
    name: "Raymond",
    image: "/assets/images/brands/raymond.png",
    type: null,
    featured: false
}, {
    id: "8a083533-65f1-497b-bea8-025b3e4e5bb3",
    slug: "apple",
    name: "Apple",
    image: "/assets/images/brands/apple-2.png",
    type: null,
    featured: true
}, {
    id: "fab5ba50-9b12-44e1-9a35-c180a4ce7253",
    slug: "amazon",
    name: "Amazon",
    image: "/assets/images/brands/amazon.png",
    type: null,
    featured: false
}, {
    id: "46fd2b1b-89e6-4e38-9a3e-6de7bb2777f3",
    slug: "alibaba",
    name: "AliBaba",
    image: "/assets/images/brands/alibaba.png",
    type: null,
    featured: true
}, {
    id: "c938bf0a-b752-44e8-97c7-dcbb03959b33",
    slug: "ebay",
    name: "ebay",
    image: "/assets/images/brands/ebay.png",
    type: null,
    featured: true
}];


export default ({auth, mainCarouselData, serviceList, categories, products, canResetPassword, shops}) => {
    return (
        <>
            <Head title="Home"/>
            <AppLayout categories={categories} auth={auth} canResetPassword={canResetPassword}>
                <Box bgcolor="#F6F6F6">
                    {
                        /* HERO SLIDER AND GRID */
                    }
                    <Section1 carouselData={mainCarouselData}/>

                    {
                        /* CATEGORIES AND ANIMATED OFFER BANNER */
                    }
                    <Section3 categories={categories}/>

                    {
                        /*  FEATURED Shops */
                    }
                    <Shops shops={shops} />

                    {
                        /* SELECTED PRODUCTS */
                    }
                    <Section9 products={products}/>

                    {
                        /* SERVICE CARDS */
                    }
                    <Section2 serviceList={serviceList}/>

                    {
                        /* DEALS OF THE DAY AND OFFER BANNERS */
                    }
                    {/*<Section4 products={products} />*/}

                    {
                        /* TOP OFFER BANNERS */
                    }
                    {/*<Offers />*/}

                    {
                        /* PRODUCT ROW WITH ELECTRONICS CATEGORY LIST */
                    }
                    {/*<Section5 data={electronicsProducts} />*/}

                    {
                        /* OFFER BANNER */
                    }
                    {/*<Section6 />*/}

                    {
                        /* PRODUCT ROW WITH MEN'S FASHION CATEGORY LIST */
                    }
                    {/*<Section5 data={menFashionProducts} />*/}

                    {
                        /* OFFER BANNER */
                    }
                    {/*<Section7 />*/}

                    {
                        /* PRODUCT ROW WITH WOMEN'S FASHION CATEGORY LIST */
                    }
                    {/*<Section5 data={womenFashionProducts} />*/}

                    <div style={{padding: '1rem'}}></div>

                </Box>

                {
                    /* POPUP NEWSLETTER FORM */
                }
                {/*<Newsletter />*/}
            </AppLayout>
        </>
    );
}
